import { v4 as uuidv4 } from 'uuid';
import ProjectImg from '../images/projectImg.png';
import UTrackerImg from '../images/utracker.jpg';
import GreenCtgImg from '../images/greenctg.jpg';
import CyberWebImg from '../images/cyber_web.jpeg';
import SCSbwImg from '../images/SCS_b&w.jpeg';
import BCPAImg from '../images/BCPA.jpeg';
import SCSImg from '../images/SCS.jpeg';
import CavinImg from '../images/cavinimg.jpg';

const projects = [
  {
    id: uuidv4(),
    name: 'Cyber Web',
    desc:
      'Cyber Web is a bootcamp on Web Application Security and Bug Bounty. It is a 3 days bootcamp where participants will learn about Web Application Security and how to secure web applications.',
    img: CyberWebImg,
  },
  {
    id: uuidv4(),
    name: 'BCPA',
    desc:
      'Workshop on Blockchain Platforms and Applications (BCPA) is a workshop series that aims to bring together student in the field of blockchain technology to discuss the current state of blockchain technology.       ',
    img: BCPAImg,
  },
  {
    id: uuidv4(),
    name: 'SCS',
    desc:
      'SCS(Symposium on Cyber Security) is a workshop series that aims to bring together researchers  in the field of Cyber Security to discuss the current state of Cyber Security and its applications.',
    img: SCSImg,
  },
];

export default projects;
